import {
  UserInfo,
  ConversationRequest,
  Conversation,
  ChatMessage,
  CosmosDBHealth,
  CosmosDBStatus,
  UserLoginDetails,
  ShareModal,
} from "./models";
import { ReportinghubDomain } from "../constants/constants";

function getHeaders(isFile = false) {
  const headers: { [key: string]: string } = {};
  if (!isFile) {
    headers["Content-Type"] = "application/json";
    // Add any other static headers here
  } // Use the imported appStateContext

  // Check the auth Mode
  if (sessionStorage.getItem("isReportingHub") === "true") {
    // Add the user email to the headers
    headers["userEmail"] = sessionStorage.getItem("RHUserEmail") ?? "";
    headers["userName"] = sessionStorage.getItem("RHUserName") ?? "";
  } else if (sessionStorage.getItem("msalUserEmail")) {
    headers["userEmail"] = sessionStorage.getItem("msalUserEmail") ?? "";
    headers["userName"] = sessionStorage.getItem("msalUserName") ?? "";
  }

  return headers;
}

// to be called on load
export async function getUserInfo(): Promise<UserInfo[]> {
  const response = await fetch("/.auth/me");
  if (!response.ok) {
    console.log("No identity provider found. Access to chat will be blocked.");
    return [];
  }

  const payload = await response.json();
  return payload;
}
export const historyEnsure = async (): Promise<CosmosDBHealth> => {
  const response = await fetch("/history/ensure", {
    method: "GET",
    headers: getHeaders(),
  })
    .then(async (res) => {
      let respJson = await res.json();
      let formattedResponse;
      if (respJson.message) {
        formattedResponse = CosmosDBStatus.Working;
      } else {
        if (res.status === 500) {
          formattedResponse = CosmosDBStatus.NotWorking;
        } else {
          formattedResponse = CosmosDBStatus.NotConfigured;
        }
      }
      if (!res.ok) {
        return {
          cosmosDB: false,
          status: formattedResponse,
        };
      } else {
        return {
          cosmosDB: true,
          status: formattedResponse,
        };
      }
    })
    .catch((err) => {
      console.error("There was an issue fetching your data.");
      return {
        cosmosDB: false,
        status: err,
      };
    });
  return response;
};

export const frontendSettings = async (): Promise<Response | null> => {
  const response = await fetch("/frontend_settings", {
    method: "GET",
    headers: getHeaders(),
  })
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      console.error("There was an issue fetching your data.");
      return null;
    });

  return response;
};

export const historyList = async (
  offset = 0
): Promise<Conversation[] | null> => {
  const response = await fetch(`/history/list?offset=${offset}`, {
    method: "GET",
    headers: getHeaders(),
  })
    .then(async (res) => {
      const payload = await res.json();
      if (!Array.isArray(payload)) {
        console.error("There was an issue fetching your data.");
        return null;
      }
      const conversations: Conversation[] = await Promise.all(
        payload.map(async (conv: any) => {
          let convMessages: ChatMessage[] = [];
          convMessages = await historyRead(conv.id)
            .then((res) => {
              return res;
            })
            .catch((err) => {
              console.error("error fetching messages: ", err);
              return [];
            });
          const conversation: Conversation = {
            id: conv.id,
            title: conv.title,
            date: conv.updatedAt,
            messages: convMessages,
            summary: conv.summary,
          };
          return conversation;
        })
      );
      return conversations;
    })
    .catch((err) => {
      console.error("There was an issue fetching your data.");
      return null;
    });

  return response;
};

export const historyRead = async (convId: string): Promise<ChatMessage[]> => {
  const response = await fetch("/history/read", {
    method: "POST",
    body: JSON.stringify({
      conversation_id: convId,
    }),
    headers: getHeaders(),
  })
    .then(async (res) => {
      if (!res) {
        return [];
      }
      const payload = await res.json();
      let messages: ChatMessage[] = [];
      if (payload?.messages) {
        payload.messages.forEach((msg: any) => {
          const message: ChatMessage = {
            id: msg.id,
            role: msg.role,
            date: msg.createdAt,
            content: msg.content,
            feedback: msg.feedback ?? undefined,
            language: msg.language ?? "",
            isEdited: msg.isEdited ?? false,
            isRegenerated: msg.isRegenerated ?? false,
          };
          messages.push(message);
        });
      }
      return messages;
    })
    .catch((err) => {
      console.error("There was an issue fetching your data.");
      return [];
    });
  return response;
};

export const historyGenerate = async (
  options: ConversationRequest,
  abortSignal: AbortSignal,
  cancel?: Boolean,
  convId?: string
): Promise<Response> => {
  let body;
  if (convId) {
    body = cancel
      ? JSON.stringify({
          conversation_id: convId,
          messages: options.messages,
          cancel_flag: cancel ? "True" : "false",
        })
      : JSON.stringify({
          conversation_id: convId,
          messages: options.messages,
        });
  } else {
    body = cancel
      ? JSON.stringify({
          messages: options.messages,
          cancel_flag: cancel ? "True" : "false",
        })
      : JSON.stringify({
          messages: options.messages,
        });
  }
  const response = await fetch("/history/generate", {
    method: "POST",
    headers: getHeaders(),
    body: body,
    signal: abortSignal,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error("There was an issue fetching your data.");

      if (err.name === "AbortError") {
        let errRes: Response = {
          ...new Response(),
          ok: false,
          status: 200,
        };
        console.log("Fetch aborted");
        return errRes;
      }
      return new Response();
    });

  return response;
};

export const historyUpdate = async (
  messages: ChatMessage[],
  convId: string
): Promise<Response> => {
  const response = await fetch("/history/update", {
    method: "POST",
    body: JSON.stringify({
      conversation_id: convId,
      messages: messages,
    }),
    headers: getHeaders(),
  })
    .then(async (res) => {
      return res;
    })
    .catch((err) => {
      console.error("There was an issue fetching your data.");
      let errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export const historyDelete = async (convId: string): Promise<Response> => {
  const response = await fetch("/history/delete", {
    method: "DELETE",
    body: JSON.stringify({
      conversation_id: convId,
    }),
    headers: getHeaders(),
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error("There was an issue fetching your data.");
      let errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export const historyDeleteAll = async (): Promise<Response> => {
  const response = await fetch("/history/delete_all", {
    method: "DELETE",
    body: JSON.stringify({}),
    headers: getHeaders(),
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error("There was an issue fetching your data.");
      let errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export const historyClear = async (convId: string): Promise<Response> => {
  const response = await fetch("/history/clear", {
    method: "POST",
    body: JSON.stringify({
      conversation_id: convId,
    }),
    headers: getHeaders(),
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error("There was an issue fetching your data.");
      let errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export const historyRename = async (
  convId: string,
  title: string
): Promise<Response> => {
  const response = await fetch("/history/rename", {
    method: "POST",
    body: JSON.stringify({
      conversation_id: convId,
      title: title,
    }),
    headers: getHeaders(),
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error("There was an issue fetching your data.");
      let errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export const historyMessageFeedback = async (
  messageId: string,
  feedback: string
): Promise<Response> => {
  const response = await fetch("/history/message_feedback", {
    method: "POST",
    body: JSON.stringify({
      message_id: messageId,
      message_feedback: feedback,
    }),
    headers: getHeaders(),
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error("There was an issue logging feedback.");
      let errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

// import { chatHistorySampleData } from "../constants/chatHistory";

export async function conversationApi(
  options: ConversationRequest,
  abortSignal: AbortSignal
): Promise<Response> {
  const response = await fetch("/conversation", {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      messages: options.messages,
    }),
    signal: abortSignal,
  });

  return response;
}
// export const fetchChatHistoryInit = async (): Promise<Conversation[] | null> => {
// export const fetchChatHistoryInit = (): Conversation[] | null => {
//     // Make initial API call here

//     // return null;
//     return chatHistorySampleData;
// }

/**
 * Retrieves the user name from the Reporting Hub.
 * @returns A promise that resolves to an array containing the user name.
 */
export async function getRHUserName(): Promise<string> {
  const response = await fetch(ReportinghubDomain + "/Profile/GetName", {
    credentials: "include",
    cache: "reload",
  });
  if (!response.ok) {
    console.log("Failed to get user name from RH");
    return "";
  }

  const payload = await response.text();
  return payload;
}

/**
 * Retrieves the Reporting Hub authentication URL.
 * @returns A promise that resolves to the authentication URL.
 */
export async function getRHAuthUrl(): Promise<string> {
  const response = await fetch("/getReportingHubAuthUrl");
  if (!response.ok) {
    console.log("Failed to get auth url from RH");
    return "";
  }

  const payload = await response.json();
  return payload;
}

/**
 * Retrieves the email of the reporting hub user.
 * @param code - The code used to retrieve the email.
 * @returns A promise that resolves to the user's email.
 */
export async function getReportingHubUserEmail(code: string): Promise<string> {
  const response = await fetch("/getReportingHubUserEmail", {
    method: "POST",
    body: JSON.stringify({
      code: code,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
    console.log("Failed to get user email from RH");
    return "";
  }

  const payload = await response.json();
  return payload;
}

export async function logUserLoginDetails(
  userLoginDetails: UserLoginDetails
): Promise<Response> {
  // Remove the extra colon after Promise
  const response = await fetch("/loguserlogindetails", {
    method: "POST",
    body: JSON.stringify({
      userLoginDetails: userLoginDetails,
    }),
    headers: getHeaders(),
  });
  if (!response.ok) {
    console.log("Failed to log user login details");
  }

  return response;
}

export async function getPreDefinedQuestions(): Promise<Response> {
  const response = await fetch("/cards/random", {
    method: "GET",
    headers: getHeaders(),
  });
  if (!response.ok) {
    console.log("Failed to get pre-defined questions");
  }

  return response.json();
}
export async function getUploadedDocument(): Promise<Response> {
  const response = await fetch("/document_upload_history", {
    method: "GET",
    headers: getHeaders(),
  });
  if (!response.ok) {
    let errRes: Response = {
      ...new Response(),
      ok: false,
      status: 500,
    };
    return errRes;
  }

  return response;
}
export async function getUploadAcess(): Promise<Response> {
  const response = await fetch("/accessUpload", {
    method: "GET",
    headers: getHeaders(),
  });
  if (!response.ok) {
    let errRes: Response = {
      ...new Response(),
      ok: false,
      status: 500,
    };
    return errRes;
  }

  return response;
}
export async function selfUploadApi(data: FormData): Promise<Response> {
  const response = await fetch("/upload_documents", {
    method: "POST",
    body: data,
    headers: getHeaders(true),
  });
  if (!response.ok) {
    let errRes: Response = {
      ...new Response(),
      ok: false,
      status: 500,
    };
    return errRes;
  }

  return response;
}

export async function shareModalAPI(data: ShareModal): Promise<Response> {
  const response = await fetch("/share_conversation", {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    let errRes: Response = {
      ...new Response(),
      ok: false,
      status: 500,
    };
    return errRes;
  }

  return response;
}

export async function getallcompanies(): Promise<Response> {
  const response = await fetch("/dropdown/companyname", {
    method: "GET",
    headers: getHeaders(),
  });
  if (!response.ok) {
    let errRes: Response = {
      ...new Response(),
      ok: false,
      status: 500,
    };
    return errRes;
  }

  return response;
}

export async function getallindustries(): Promise<Response> {
  const response = await fetch("/dropdown/sector", {
    method: "GET",
    headers: getHeaders(),
  });
  if (!response.ok) {
    let errRes: Response = {
      ...new Response(),
      ok: false,
      status: 500,
    };
    return errRes;
  }

  return response;
}

export async function getalllocations(): Promise<Response> {
  const response = await fetch("/dropdown/location", {
    method: "GET",
    headers: getHeaders(),
  });
  if (!response.ok) {
    let errRes: Response = {
      ...new Response(),
      ok: false,
      status: 500,
    };
    return errRes;
  }

  return response;
}

export async function getShareData(data): Promise<Response> {
  const response = await fetch("/share", {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    let errRes: Response = {
      ...new Response(),
      ok: false,
      status: 500,
    };
    return errRes;
  }

  return response;
}

export async function getUserProfileDetails(): Promise<Response> {
  const response = await fetch("/fetch_userprofile", {
    method: "POST",
    headers: getHeaders(),
  });
  if (!response.ok) {
    let errRes: Response = {
      ...new Response(),
      ok: false,
      status: 500,
    };
    return errRes;
  }

  return response;
}

export async function updateUserProfileDetails(data): Promise<Response> {
  const response = await fetch("/upsert_userprofile", {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    let errRes: Response = {
      ...new Response(),
      ok: false,
      status: response.status,
    };
    return errRes;
  }

  return response;
}

export async function getSharedChat(id): Promise<Response> {
  const response = await fetch("/share/login", {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ conversation_id: id }),
  });
  if (!response.ok) {
    let errRes: Response = {
      ...new Response(),
      ok: false,
      status: 500,
    };
    return errRes;
  }

  return response;
}
