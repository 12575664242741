import Header from "../header/Header";
import Chat from "../chat/Chat";
import styles from "../../CSS/Layout.module.css";
import { useContext, useEffect, useState } from "react";
import { AppStateContext } from "../../../state/CarbonAIChat/AppProvider";
import Logo from "../../../assets/logo.svg";
import Avatar from "../../../assets/android CarbonAI.svg";
import Footer from "../../../components/Share/ShareBtns/ShareBtn";
const Layout = () => {
  const appStateContext = useContext(AppStateContext);

  return (
    <div>
      <div className={`${styles.LayoutShareChat} bgColorPrimary`}>
        <Header />
        <div className={styles.footerText}>
          This is a copy of a conversation between ChatGPT & Anonymous.
        </div>
        <Chat />
        <footer
          className={
            appStateContext?.state.isSmallScreen
              ? `${styles.shareChatFooterSmallScreen}`
              : `${styles.shareChatFooter}`
          }
        >
          <img
            alt="logo"
            src={Logo}
            className={`${styles.headerIcon}`}
            aria-hidden="true"
          />
          <span>
            <img
              alt="logo"
              src={Avatar}
              className={`${styles.headerIcon}`}
              aria-hidden="true"
            />
            <span>
              <b>CarbonAI</b>
            </span>
          </span>
          <span className={styles.footerText}>
            CarbonAI is pretty smart, but like all of us, it can make mistakes.
            It's always a good idea to double-check important information!
          </span>
        </footer>
      </div>
      {/* <footer className={styles.shareFooter}>
        <Footer />
      </footer> */}
    </div>
  );
};

export default Layout;
